import { getAssertMessage } from './get-assert-message';
import {
    testArrayHasIndex,
    testIsArray,
    testIsBoolean,
    testIsEmptyArray,
    testIsEmptyString,
    testIsFalse,
    testIsFunction,
    testIsNotNull,
    testIsNull,
    testIsNumber,
    testIsObject,
    testIsString,
    testIsTrue,
    testIsUUID4,
    testObjectHasKey,
    testObjectHasValue,
} from './test-base';

///////////////////////////////
// TESTS FOR VALUES
///////////////////////////////
var assertIsTrue = function (value, message) {
    if (!testIsTrue(value)) {
        throw new Error(getAssertMessage('value is not true', message));
    }
};

var assertIsFalse = function (value, message) {
    if (!testIsFalse(value)) {
        throw new Error(getAssertMessage('value is not false', message));
    }
};

var assertIsNull = function (value, message) {
    if (!testIsNull(value)) {
        throw new Error(getAssertMessage('value is not null', message));
    }
};

var assertIsNotNull = function (value, message) {
    if (!testIsNotNull(value)) {
        throw new Error(getAssertMessage('value is null', message));
    }
};

///////////////////////////////
// TESTS FOR TYPES
///////////////////////////////
var assertIsBoolean = function (value, message) {
    if (!testIsBoolean(value)) {
        throw new Error(getAssertMessage('value is not a boolean', message));
    }
};

var assertIsNotBoolean = function (value, message) {
    if (testIsBoolean(value)) {
        throw new Error(getAssertMessage('value is a boolean', message));
    }
};

var assertIsFunction = function (value, message) {
    if (!testIsFunction(value)) {
        throw new Error(getAssertMessage('value is not a function', message));
    }
};

var assertIsObject = function (value, message) {
    if (!testIsObject(value)) {
        throw new Error(getAssertMessage('value is not an object', message));
    }
};

var assertIsArray = function (value, message) {
    if (!testIsArray(value)) {
        throw new Error(getAssertMessage('value is not an array', message));
    }
};

var assertIsNotArray = function (value, message) {
    if (testIsArray(value)) {
        throw new Error(getAssertMessage('value is an array', message));
    }
};

var assertIsNumber = function (value, message) {
    if (!testIsNumber(value)) {
        throw new Error(getAssertMessage('value is not a number', message));
    }
};

var assertIsString = function (string, message) {
    if (!testIsString(string)) {
        throw new Error(getAssertMessage('value is not a string', message));
    }
};

///////////////////////////////
// TESTS FOR OTHER THINGS
///////////////////////////////
var assertIsUUID4 = function (uuid4, message) {
    var m = 'value is not a uuid4';

    if (typeof message === 'string') {
        m = message;
    }

    assertIsString(uuid4, m);

    if (!testIsUUID4(uuid4)) {
        throw new Error(getAssertMessage(m));
    }
};

var assertIsNonEmptyString = function (string, message) {
    if (!testIsString(string)) {
        throw new Error(getAssertMessage('value is not a string', message));
    }

    if (testIsEmptyString(string)) {
        throw new Error(getAssertMessage('value is an empty string', message));
    }
};

var assertIsEmptyArray = function (value, message) {
    if (!testIsArray(value)) {
        throw new Error(getAssertMessage('value is not an array', message));
    }

    if (!testIsEmptyArray(value)) {
        throw new Error(getAssertMessage('array is not empty', message));
    }
};

var assertIsNonEmptyArray = function (value, message) {
    if (!testIsArray(value)) {
        throw new Error(getAssertMessage('value is not an array', message));
    }

    if (testIsEmptyArray(value)) {
        throw new Error(getAssertMessage('array is empty', message));
    }
};

var assertArrayHasIndex = function (value, index, message) {
    assertIsArray(value, message);
    assertIsNumber(index, message);

    if (!testArrayHasIndex(value, index)) {
        throw new Error(getAssertMessage('array does not have index', message));
    }
};

var assertObjectHasKey = function (object, key, message) {
    if (!testObjectHasKey(object, key)) {
        throw new Error(getAssertMessage('object does not have key: ' + key, message));
    }
};

var assertObjectHasNotKey = function (object, key, message) {
    if (testObjectHasKey(object, key)) {
        throw new Error(getAssertMessage('object does have key: ' + key, message));
    }
};

var assertObjectHasValue = function (object, value, message) {
    if (!testObjectHasValue(object, value)) {
        throw new Error(getAssertMessage('object does not have value:' + value, message));
    }
};

export { assertIsTrue };
export { assertIsFalse };
export { assertIsNull };
export { assertIsNotNull };
export { assertIsBoolean };
export { assertIsNotBoolean };
export { assertIsFunction };
export { assertIsObject };
export { assertIsArray };
export { assertIsNotArray };
export { assertIsNumber };
export { assertIsString };
export { assertIsUUID4 };
export { assertIsNonEmptyString };
export { assertIsEmptyArray };
export { assertIsNonEmptyArray };
export { assertArrayHasIndex };
export { assertObjectHasKey };
export { assertObjectHasNotKey };
export { assertObjectHasValue };
