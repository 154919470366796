import {
    assertIsArray,
    assertIsBoolean,
    assertIsNotNull,
    assertIsNumber,
    assertIsObject,
    assertIsString,
} from './assert-base';

///////////////////////////////
// TESTS FOR TYPES
///////////////////////////////
/**
 * Returns true if value has type boolean
 *
 * @param {boolean} value
 *
 * @return {boolean}
 */
var testIsBoolean = function (value) {
    return typeof value === 'boolean';
};

/**
 * Returns true if value has type function
 *
 * @param {function} value
 *
 * @return {boolean}
 */
var testIsFunction = function (value) {
    return typeof value === 'function';
};

/**
 *
 * Returns true if value has type object
 *
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#null
 *
 * @param {object} value
 *
 * @return {boolean}
 */
var testIsObject = function (value) {
    return typeof value === 'object' && value !== null;
};

/**
 *
 * Returns true if value has type array
 *
 * @param {Array} value
 *
 * @return {boolean}
 */
var testIsArray = function (value) {
    return Array.isArray(value);
};

/**
 * Returns true if value has type number
 *
 * @param {number} value
 *
 * @return {boolean}
 */
var testIsNumber = function (value) {
    return typeof value === 'number';
};

/**
 * Returns true if value has type string
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsString = function (value) {
    return typeof value === 'string';
};

/**
 * Returns true if value is a valid uuid4 string. Returns false in all other cases
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsUUID4 = function (value) {
    if (!testIsString(value)) {
        return false;
    }

    // A valid UUID version 4 must have these two things:
    // 1) the 3rd group must start with a "4"
    // 2) the 4th group must start with one of "89ab"
    // all other values can be [0-9a-fA-F]
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value);
};

/**
 * - Returns true if string is valid UUID4 string
 * - Returns false if string is not a valid UUID4 string
 * - Throws an exception for all other types of input
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsUUID4Strict = function (value) {
    assertIsString(value);

    return testIsUUID4(value);
};

///////////////////////////////
// TESTS FOR VALUES
///////////////////////////////

/**
 * - Returns true if value is truthy
 * - Returns false if value is falsy
 *
 * JS considers these 7 values as falsy:
 *
 * false, 0, '', null, undefined, Nan
 *
 * All other values are truthy
 *
 * https://developer.mozilla.org/en-US/docs/Glossary/falsy
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsTrue = function (value) {
    return Boolean(value);
};

/**
 * - Returns true if value is false
 * - Returns false if value is truthy
 *
 * JS considers these 7 values as falsy:
 *
 * false, 0, '', null, undefined, Nan
 *
 * All other values are truthy
 *
 * https://developer.mozilla.org/en-US/docs/Glossary/falsy
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsFalse = function (value) {
    return !Boolean(value);
};

/**
 * - Returns true if value is a boolean true
 * - Returns false if value is a boolean false
 * - Throws an exception for all other inputs
 *
 * @param {boolean} value
 *
 * @return {boolean}
 */
var testIsTrueStrict = function (value) {
    assertIsBoolean(value);

    return value === true;
};
/**
 * - Returns true if value is a boolean false
 * - Returns false if value is a boolean true
 * - Throws an exception for all other inputs
 *
 * @param {boolean} value
 *
 * @return {boolean}
 */
var testIsFalseStrict = function (value) {
    assertIsBoolean(value);

    return value === true;
};

/**
 * Returns true if value is true-ish
 *
 * If value is a string then we apply value.trim().toLocaleLowerCase() and handle the following
 * special cases (inspired by PHPs FILTER_VALIDATE_BOOLEAN):
 *
 * - Returns a boolean true for '1', 'true', 'on' and 'yes'
 * - Returns false for all other string values
 *
 * For all other inputs we use the defaults JS logic to convert it into a boolean
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsTrueish = function (value) {
    if (typeof value === 'string') {
        var lower = value.trim().toLocaleLowerCase();

        return lower === '1' || lower === 'true' || lower === 'on' || lower === 'yes';
    }

    return Boolean(value);
};

/**
 * Returns true if value is false-ish
 *
 * If value is a string then we apply value.trim().toLocaleLowerCase() and handle the following
 * special cases (inspired by PHPs FILTER_VALIDATE_BOOLEAN):
 *
 * - Returns a true for '', '0', 'true', 'on' and 'yes'
 * - Returns false for all other string values
 *
 * For all other inputs we use the defaults JS logic to convert it into a boolean
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsFalseish = function (value) {
    if (typeof value === 'string') {
        var lower = value.trim().toLocaleLowerCase();

        return lower === '' || lower === '0' || lower === 'false' || lower === 'off' || lower === 'no';
    }

    return !Boolean(value);
};

/**
 * Returns true if value is null
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsNull = function (value) {
    return value === null;
};

/**
 * Returns true if value is null
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsNotNull = function (value) {
    return value !== null;
};

/**
 * Returns true if value is undefined
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsUndefined = function (value) {
    return typeof value === 'undefined';
};

/**
 * Returns true if value is not undefined
 *
 * @param value
 *
 * @return {boolean}
 */
var testIsNotUndefined = function (value) {
    return typeof value !== 'undefined';
};

///////////////////////////////
// TESTS FOR OTHER THINGS
///////////////////////////////
/**
 * - Returns true if string is empty (trim() is not used)
 * - Returns false for all other inputs
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsEmptyString = function (value) {
    return testIsString(value) && value === '';
};

/**
 * - Returns true if string is empty (trim() is not used)
 * - Returns false is string is not empty
 * - Throws an exception for all other input types
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsEmptyStringStrict = function (value) {
    assertIsString(value);

    return testIsEmptyString(value);
};

/**
 * Returns true if string is not empty (trim() is not used)
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsNonEmptyString = function (value) {
    return testIsString(value) && value !== '';
};

/**
 * Returns true if string is empty after using trim()
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsTrimmedStringEmpty = function (value) {
    if (!testIsString(value)) {
        return false;
    }

    return testIsEmptyString(value.trim());
};

/**
 * Returns true if string is not empty after using trim()
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsTrimmedStringNotEmpty = function (value) {
    if (!testIsString(value)) {
        return false;
    }

    return testIsNonEmptyString(value.trim());
};

/**
 * - Returns true if string is not empty (trim() is not used)
 * - Returns false if string is empty
 *
 * @param {string} value
 *
 * @return {boolean}
 */
var testIsNonEmptyStringStrict = function (value) {
    assertIsString(value);

    return testIsNonEmptyString(value);
};

/**
 * Returns true is value is an empty array. false in all other cases
 *
 * @param {array} value
 *
 * @return {boolean}
 */
var testIsEmptyArray = function (value) {
    return testIsArray(value) && value.length === 0;
};

/**
 * Returns true/false if value is an array. Throws an exception in all other cases
 *
 * @param {array} value
 *
 * @return {boolean}
 */
var testIsEmptyArrayStrict = function (value) {
    assertIsArray(value);

    return testIsEmptyArray(value);
};

/**
 * Returns true if array has index
 *
 * @param {array} array
 * @param {number} index
 *
 * @return {boolean}
 */

var testArrayHasIndex = function (array, index) {
    assertIsArray(array);
    assertIsNumber(index);

    return typeof array[index] !== 'undefined';
};

/**
 * Returns true if array contains a value
 *
 * @param {array} array
 * @param value
 *
 * @return {boolean}
 */

var testArrayContainsValue = function (array, value) {
    assertIsArray(array);

    var length = array.length;

    for (var i = 0; i < length; i++) {
        if (value === array[i]) {
            return true;
        }
    }

    return false;
};

/**
 * Returns true if object has key
 *
 * @param {Object} object
 * @param key
 *
 * @return {boolean}
 */
var testObjectHasKey = function (object, key) {
    assertIsObject(object);
    assertIsNotNull(key);

    return object.hasOwnProperty(key);
};

/**
 * Returns true if object has key
 *
 * @param {Object} object
 * @param {String[]} keys
 *
 * @return {boolean}
 */
var testObjectHasKeys = function (object, keys) {
    assertIsObject(object);
    assertIsArray(keys);

    let valid = true;

    const objectKeys = Object.keys(object);

    keys.forEach((key) => {
        if (!objectKeys.includes(key)) {
            valid = false;
        }
    });

    return valid;
};

/**
 * Returns true if object has value
 *
 * @param {object} object
 * @param value
 *
 * @return {boolean}
 */
var testObjectHasValue = function (object, value) {
    assertIsObject(object);

    var found = false;

    var findValue = function (key, objectValue) {
        if (value === objectValue) {
            found = true;
            return false;
        }
    };

    $.each(object, findValue);

    return found;
};

/**
 * @param {string} errorObject
 *
 * @returns {Boolean}
 */
function testIsJsError(errorObject) {
    // based on:
    //
    // https://stackoverflow.com/a/61958148
    //
    // > This behavior is guaranteed by the ECMAScript Language Specification.
    // >
    // > Error instances inherit properties from the Error prototype object and
    // > their [[Class]] internal property value is "Error". Error instances have
    // > no special properties.
    //
    // -> If it is an internal error then the result is: "[object Error]"
    const stringRepresentation = Object.prototype.toString.call(errorObject);

    const regex = /\[\ *object\ +error\ *\]/gi;

    return stringRepresentation.match(regex) !== null;
}

function testIsNumberIsh(value) {
    return !testIsBoolean(value) && value !== null && !isNaN(value);
}

function testIsNotNumberIsh(value) {
    return testIsBoolean(value) || value === null || isNaN(value);
}

function testIsNonEmptyObject(value) {
    return Object.keys(value).length !== 0;
}

export { testIsNumberIsh };
export { testIsNotNumberIsh };
export { testIsTrue };
export { testIsTrueStrict };
export { testIsTrueish };
export { testIsFalse };
export { testIsFalseStrict };
export { testIsFalseish };
export { testIsNull };
export { testIsNotNull };
export { testIsUndefined };
export { testIsNotUndefined };
export { testIsBoolean };
export { testIsFunction };
export { testIsObject };
export { testIsArray };
export { testIsNumber };
export { testIsString };
export { testIsUUID4 };
export { testIsUUID4Strict };
export { testIsEmptyString };
export { testIsEmptyStringStrict };
export { testIsNonEmptyString };
export { testIsNonEmptyStringStrict };
export { testIsEmptyArray };
export { testIsEmptyArrayStrict };
export { testArrayHasIndex };
export { testArrayContainsValue };
export { testObjectHasKey };
export { testObjectHasKeys };
export { testObjectHasValue };
export { testIsJsError };
export { testIsTrimmedStringEmpty };
export { testIsTrimmedStringNotEmpty };
export { testIsNonEmptyObject };
