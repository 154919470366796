/**
 * This is used for error messages in the asserts
 *
 * @param {string} defaultMessage
 * @param {string} [message]
 * @return {string}
 */
var getAssertMessage = function (defaultMessage, message) {
    if (typeof message === 'string') {
        return 'qgAssert: ' + message;
    }

    return 'qgAssert: ' + defaultMessage;
};

export { getAssertMessage };
