var globalLogger = {
    addLogData: function (key, value) {
        try {
            window.qgLogger.addLogData(key, value);
        } catch (e) {
            //do nothing
        }
    },
    log: function (message, extra, withBeacon) {
        if (!withBeacon) {
            withBeacon = false;
        }

        try {
            window.qgLogger.log(message, extra, undefined, withBeacon);
        } catch (e) {
            //do nothing
        }
    },
    debug: function (message, extra) {
        try {
            window.qgLogger.debug(message, extra);
        } catch (e) {
            //do nothing
        }
    },
    info: function (message, extra) {
        try {
            window.qgLogger.info(message, extra);
        } catch (e) {
            //do nothing
        }
    },
    notice: function (message, extra) {
        try {
            window.qgLogger.notice(message, extra);
        } catch (e) {
            //do nothing
        }
    },
    warning: function (message, extra) {
        try {
            window.qgLogger.warning(message, extra);
        } catch (e) {
            //do nothing
        }
    },
    error: function (message, extra) {
        try {
            window.qgLogger.error(message, extra);
        } catch (e) {
            //do nothing
        }
    },
    critical: function (message, extra) {
        try {
            window.qgLogger.critical(message, extra);
        } catch (e) {
            //do nothing
        }
    },
};

export { globalLogger };
